import * as React from "react"
import { HelmetDatoCms } from 'gatsby-source-datocms'
import { graphql } from 'gatsby'
import Layout from "../components/layout"
import PageBuilder from "../builder/page-builder"
import Hero from "../builder/hero"

const Event = ({ data }) => {
  return (
    <Layout>
        <main>
            {data.event.nodes.map(event => (
                <>
                    <HelmetDatoCms 
                        seo={event.seoMetaTags}
                    />
                    <style>
                        {`body {background-color: ${event.backgroundColour.hex}`}
                    </style>
                    <Hero data={event} />
                    <PageBuilder data={event.content} />
                </>
            ))}
        </main>
    </Layout>
  )
}

export default Event

export const query = graphql`
    query
    (
    $id: String!) 
    {
        event: allDatoCmsEvent(filter: {id: {eq: $id}}) {
            nodes {
                title
                slug
                heroSize
                backgroundColour {
                    hex
                }
                heroBackground {
                    ... on DatoCmsBackgroundImage {
                        __typename
                        scrim
                        imageBackground {
                            url(imgixParams: {w: "1920"})
                        }
                        imageForeground {
                            url(imgixParams: {w: "1080"})
                        }
                    }
                    ... on DatoCmsBackgroundVideo {
                        __typename
                        scrim
                        link
                        linkMobile
                        videoPoster {
                            url(imgixParams: {w: "1440"})
                        }
                    }
                }
                heroContent {
                    blocks {
                        ... on DatoCmsIconContent {
                            ...IconContent
                        }
                        ... on DatoCmsLogo {
                            ...LogoContent
                        }
                        ... on DatoCmsButton {
                            ...ButtonContent
                        }
                    }
                    links
                    value
                }
                heroBottomItems {
                    id
                    icon
                    url
                    text
                }
                seoMetaTags {
                    ...GatsbyDatoCmsSeoMetaTags
                }
                ...EventContent
            }
        }
}
`;